
/* This file just contains a bunch of PwC branding overrides hackily added with important tags*/

/*
.form-control {
    border-radius: 0rem;
}*/

div {
    text-transform: none;
    text-align: left;
}

.navbar-brand {
    padding-top: 40px;
    padding-bottom: 0px;
}

.MuiSwitch-track {
    background-color: #E0301E !important;
    border-radius: 7px !important;
}

button:disabled,
button[disabled]{
  background-color: #7D7D7D !important;
  border-color: #7D7D7D !important;
  opacity: 1 !important;
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled,
.Mui-disabled{
    background-color: transparent !important;
}

.css-1aquho2-MuiTabs-indicator,
.css-ttwr4n{
    background-color: #E0301E !important; 
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.project-nav-link,
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
.Mui-checked,
.css-1q2h7u5.Mui-selected,
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked,
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track,
.css-1m9pwf3{
    color: #E0301E !important;
}

#account-button,
.MuiSwitch-thumb{
    border-radius: 50% !important;
}

.MuiSwitch-thumb{
    border-style: solid !important;
    border-width: 1px !important;
    border-color: #E0301E !important;
}

.css-3su884-MuiPaper-root,
.MuiPaper-root,
#upload-box{
    box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 15%) !important;
}

#upload-box{
    padding: 50px;
}

.footer-nav-link{
    color: white !important;
}

.MuiTableCell-head{
    font-weight: bold !important;
}

*{
    border-radius: 0rem !important;
    text-transform: none !important;
    font-family: "Helvetica Neue LT Pro", Arial, sans-serif !important;
}

#vtt-logo{
    font-family: "ITC Charter Com", Georgia, serif !important;
}

#account-button-text{
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 105%;
}

.MuiButton-root, .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderTitle {
    padding: 0px 0px !important;
    min-width: 0px !important;
    font-weight:600 !important;
}

.MuiButtonBase-root{
    font-weight:600 !important;
}

.MuiDataGrid-columnHeader{
    padding: 0px 7px !important;
}

.home-grid{
    text-align:center
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    color:black !important;
    padding: 0px 7px !important;
}